import { gql } from '@apollo/client'

const QueryData = gql`
  query Config {
    kiosk {
      config {
        name
        language {
          default
          availableLanguages
        }
        sessionBehaviour {
          logoutAfterRequest
          logoutAfterLoginInSeconds
          autoPerformRequestAfterLogin
        }
        loginMethods
      }
    }
  }
`
export default QueryData
