import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  resources: {
    de: { translations: require('./de.json') },
    en: { translations: require('./en.json') },
    es: { translations: require('./es.json') },
    fr: { translations: require('./fr.json') },
  },
  ns: ['translations']
})

export default i18n
