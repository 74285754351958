import { gql } from '@apollo/client'

const QueryData = gql`
  query rooms {
    kiosk {
      rooms {
        nodes {
          ... on LockControlPort {
            __typename
            id
            name
          }
          ... on TwoWireLock {
            __typename
            id
            name
          }
        }
      }
    }
  }
`
export default QueryData
