import { gql } from '@apollo/client'

const QueryData = gql`
  query lockers {
    kiosk {
      lockers {
        nodes {
          ... on LockControlPort {
            __typename
            id
            name
          }
          ... on TwoWireLock {
            __typename
            id
            name
          }
        }
      }
    }
  }

`
export default QueryData
