import React, { useState, useEffect } from 'react'
import Device from '../components/Device'
import RoomQuery from '../gql/rooms.js'
import LockerQuery from '../gql/lockers.js'
import FullscreenStatus from '../components/FullscreenStatus'
import {removeCookie} from '../tools/cookie.js'
import {useQuery} from '@apollo/client'
import { useTranslation } from 'react-i18next'
import iconCheckGreen from '../images/icon-check-green.svg'
import timers from '../timers.js'

const Devices = (props) => {
  const {loading: roomLoading, data: roomData} = useQuery(
    RoomQuery,
    {
      variables: {},
      fetchPolicy: "network-only",
      context: {
        headers: {'X-Sensorberg-Kiosk-Client-Token': props.kioskClientToken}
      },
    }
  )

  const {loading: lockerLoading, data: lockerData} = useQuery(
    LockerQuery,
    {
      variables: {},
      fetchPolicy: "network-only",
      context: {
        headers: {'X-Sensorberg-Kiosk-Client-Token': props.kioskClientToken}
      },
    }
  )

  const [showLoadingOverlay, setShowLoadingOverlay] = useState("loading")
  const [autoOpen, setAutoOpen] = useState(false)
  const [autoOpenDeviceName, setAutoOpenDeviceName] = useState(false)
  const [roomList, setRoomList] = useState([])
  const [remainingSecondsUntilLogout, setRemainingSecondsUntilLogout] = useState(new Date(props.config.sessionBehaviour.logoutAfterLoginInSeconds * 1000).toISOString().slice(14, 19))

  const { t } = useTranslation()

  const setupCountdown = (duration) => {
    clearInterval(window.logoutInSecondsTimer)
    const loginAt = Math.floor(Date.now() / 1000)

    window.logoutInSecondsTimer = setInterval(() => {
      const remainingSeconds = loginAt - Math.floor(Date.now() / 1000) + parseInt(duration)
      const remainingTimeMmSs = new Date(remainingSeconds * 1000).toISOString().slice(14, 19)
      setRemainingSecondsUntilLogout(remainingTimeMmSs)
      remainingSeconds <= 1 && clearInterval(window.logoutInSecondsTimer)
    }, 1000)
  }

  useEffect(() => {

    const postProcess = async () => {
      setTimeout(() => { setShowLoadingOverlay(false)}, timers.logoutAfterAutoOpenDuration + timers.asyncOffsetDuration)

      if (roomLoading || lockerLoading || !roomData || !lockerData) {
        return false
      }

      if (roomData && lockerData && !roomLoading && !lockerLoading) {

        setupCountdown(props.config.sessionBehaviour.logoutAfterLoginInSeconds)

        let tempRoomList = []

        roomData.kiosk.rooms.nodes.forEach((lock) => {
          tempRoomList.push(lock)
        })

        setRoomList(tempRoomList)

        console.log(`%c[CONFIG] autoOpen config: ${props.config.sessionBehaviour.autoPerformRequestAfterLogin}`, 'background: #444; color: #eee')
        console.log(`%c[CONFIG] autoOpen config: ${props.config.sessionBehaviour.autoPerformRequestAfterLogin}`, 'background: #444; color: #eee')
        console.log("%c[DEVICES] Doors count: " + tempRoomList.length + " / Lockers count: " + lockerData.kiosk.lockers.nodes.length, 'background: #007acc; color: #fff')

        // auto-open device if configured accordingly
        if (props.config.sessionBehaviour.autoPerformRequestAfterLogin) {
          if (tempRoomList.length + lockerData.kiosk.lockers.nodes.length === 1) {
            setAutoOpen(true)
            setAutoOpenDeviceName(tempRoomList.length > 0 ? tempRoomList[0].name : lockerData.kiosk.lockers.nodes[0].actuator.name)
          }
        }
      }
    }
    postProcess()
    return () => { clearInterval(window.logoutInSecondsTimer) }
  }, [roomLoading, lockerLoading, roomData, lockerData, props.config])

  if (roomLoading || lockerLoading)
    return <i className="loading-icon loading-icon--lg absolute" style={{top: '50%', left: '50%'}}></i>

  const handleLogout = () => {
    props.onChangeComponentState("getconfigaccesstoken")
    clearTimeout(window.logoutAfterRequestTimer)
    clearInterval(window.logoutInSecondsTimer)
    removeCookie('personalBearerToken')
    console.log("%c logoutAfterRequestTimer and logoutInSecondsTimer timer cancelled", 'background-color: #8933df; color: white')
  }

  const Headline = (props) => {
    return <div className="text-white bg-blue-800 rounded-t-lg px-8 py-5 text-lg font-bold">{props.text}</div>
  }

  return <div>

    {(autoOpen) && (showLoadingOverlay === "loading") &&
      <FullscreenStatus icon={iconCheckGreen} classes="bg-green-300" text1={t('login.login_state_success_text_3_html', {deviceName: autoOpenDeviceName})} text2={t('login.login_state_success_text_4_html')} />
    }

    {(!autoOpen) && (showLoadingOverlay === "loading") &&
      <FullscreenStatus icon={iconCheckGreen} classes="bg-green-300" text1={t('login.login_state_success_text_1')} text2={t('login.login_state_success_text_2')} />
    }

    <div onClick={handleLogout} className={`
      absolute top-8 right-10 z-20
      ${showLoadingOverlay && "hidden"}
      cursor-pointer
      border
      text-blue-800 border-blue-800
      active:text-white active:bg-blue-800
      rounded
      w-24 h-24 flex items-center justify-between flex-col
      p-0`}>
      <i className="icon-exit text-2xl mt-2"></i>
      <span className="font-bold">{remainingSecondsUntilLogout}</span>
    </div>

    <p className="text-2xl font-bold text-blue-800 mt-28 mr-32">{t('devices.headline')}</p>
    <p className="text-lg text-blue-800 mb-8 sm:mr-32 md:mr-32">{t('devices.subline')}</p>

    {roomList.length > 0 &&
      <div className="shadow rounded-lg overflow-hidden mb-8">

        <Headline text={t('devices.doors_headline')} />

        <div data-testid="rooms">
          {roomList.map((lock, index) => (
            <Device
              type="door"
              key={index}
              state="closed"
              onChangeComponentState={(value) => props.onChangeComponentState(value)}
              kioskClientToken={props.kioskClientToken}
              device={lock}
              autoOpen={autoOpen}
              logoutAfterRequest={props.config.sessionBehaviour.logoutAfterRequest}
              logoutAfterAutoOpenInSeconds={timers.logoutAfterAutoOpenDuration}
              config={props.config}
            />
          ))}
        </div>
      </div>
    }

    {lockerData.kiosk.lockers.nodes.length > 0 &&
      <div className="shadow rounded-lg overflow-hidden mb-8">

        <Headline text={t('devices.lockers_headline')} />

        <div data-testid="lockers">
          {lockerData && lockerData.kiosk.lockers.nodes.map((lock, index) => (
            <Device
              type="locker"
              key={index}
              state="closed"
              onChangeComponentState={(value) => props.onChangeComponentState(value)}
              kioskClientToken={props.kioskClientToken}
              device={lock}
              autoOpen={autoOpen}
              logoutAfterRequest={props.config.sessionBehaviour.logoutAfterRequest}
              logoutAfterAutoOpenInSeconds={timers.logoutAfterAutoOpenDuration}
              config={props.config}
            />
          ))}
        </div>
      </div>
    }
  </div>
}

export default Devices
